<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

// appStore.initTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}

.el-scrollbar {
  height: 100% !important;
}
.el-card {
  border: 1px solid var(--top-header-hover-color) !important;
}

// 表格圆角
// .el-table {
//   border: 1px solid var(--el-table-border-color) !important;
//   border-radius: 10px;
// }

// .el-table--border .el-table__inner-wrapper::after,
// .el-table__border-left-patch,
// .el-table--border::before,
// .el-table--border::after,
// .el-table__inner-wrapper::before {
//   display: none !important;
// }
// .el-table--border .el-table__cell {
//   &:last-child {
//     border-right: none !important;
//   }
// }
// .el-table .el-table__row:last-child td.el-table__cell {
//   border-bottom: none !important;
// }
.el-table th.el-table__cell {
  background-color: #fafafa !important;
}

// 弹框圆角
.el-dialog {
  border-radius: 10px !important;
}

// 左侧边栏样式
.v-menu {
  // padding-top: 23px;
}
// .el-scrollbar__view {
//   padding-bottom: 30px;
// }
.el-sub-menu:last-child {
  // padding-bottom: 30px;
}
.el-menu-item {
  height: 40px !important;
}
.el-sub-menu__title {
  height: 40px !important;
}

.el-drawer__header {
  margin-bottom: none !important;
}

// 左侧导航栏对齐-----
.el-sub-menu {
  .el-menu {
    text-indent: 10px;
  }
}
// ---

.true-hover {
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.box-shadow-block {
  background-color: #fff;
  padding: 20px;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);

  border-radius: 10px;
}
.el-pagination {
  flex-wrap: wrap;
}
.el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: #f0f0f0 !important;
}

.single-line {
  white-space: nowrap; /* 文本不换行 */
  overflow: hidden; /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 溢出内容显示为省略号 */
}
</style>
