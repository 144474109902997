import request from '@/axios'
// 登录
export const loginApi = (data: any): any => {
  return request.post('/subgrade/account/user/login', data, 'json')
}
// 用户登出
export const loginOutApi = (): any => {
  return request.post('/subgrade/account/user/logout')
}
// 修改密码
export const resetPasswordApi = (data: any): any => {
  return request.post('/subgrade/account/user/resetPassword', data, 'json')
}
// 发送短信验证码场景编码
// LOGIN("login", "登录场景"),
// REGISTER("register", "注册场景"),
// RESET_PASSWORD("resetPassword", "修改密码"),
// BIND_MOBILE("bindMobile", "换绑手机号"),
// AUTHENTICATE("authenticate", "用户认证"),
// SUBMIT_RS("submitRs", "提交风险检测订单"),
// QUERY_RS("queryRs", "查询风险检测报告"),
export const sendSms = (data: any): any => {
  return request.post('/subgrade/basic/sms/sendVerifyCode', data, 'json')
}

// 人机验证
export const captchaVerify = (data: any): any => {
  return request.post('/subgrade/basic/validate/captchaVerify', data, 'json')
}

// 查询登录手机号
export const queryLoginMobile = (): any => {
  return request.get(`/subgrade/account/user/queryLoginMobile`)
}

// 账户设置
// 编辑用户信息
export const accountUserEdit = (data: any): any => {
  return request.post('/subgrade/account/user/edit', data, 'json')
}
// 绑定手机号（不验证原手机号）
export const userBindMobile = (data: any): any => {
  return request.post('/subgrade/account/user/bindMobile', data, 'json')
}
// 查询登录信息
export const queryUserSession = (): any => {
  return request.get('/subgrade/account/user/querySession')
}

// 绑定邮箱
export const userBindEmail = (data: any): any => {
  return request.post('/subgrade/account/user/bindEmail', data, 'json')
}
