import service from './service'
import { useUserStoreWithOut } from '@/store/modules/user'

const requestPost = (url: any, data: any, type: any) => {
  const userStore = useUserStoreWithOut()
  return service.request({
    url: url,
    method: 'post',
    data,
    headers: {
      'Content-Type':
        type === 'json'
          ? 'application/json'
          : type === 'form'
            ? 'multipart/form-data'
            : 'application/x-www-form-urlencoded',
      stoken: userStore.getToken ?? '',
      appcode: 'crm'
    }
  })
}
const requestPostFile = (url: any, data: any) => {
  const userStore = useUserStoreWithOut()
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return service.request({
    url: url,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      stoken: userStore.getToken ?? '',
      appcode: 'crm'
    }
  })
}
const requestGet = (url: any, data: any, type: any) => {
  const userStore = useUserStoreWithOut()
  return service.request({
    url: url,
    method: 'get',
    data,
    headers: {
      'Content-Type':
        type === 'json'
          ? 'application/json'
          : type === 'form'
            ? 'multipart/form-data'
            : 'application/x-www-form-urlencoded',
      stoken: userStore.getToken ?? '',
      appcode: 'crm'
    }
  })
}

export default {
  get: (url: any, data = {}, type = 'json') => {
    return requestGet(url, data, type)
  },
  post: (url: any, data = {}, type = 'json') => {
    return requestPost(url, data, type)
  },
  postFile: (url: any, data = {}) => {
    return requestPostFile(url, data)
  }
}
