import request from '@/axios'

// 查询部门
export const deptList = (data: any) => {
  return request.post('/mt/crm/org/dept/list', data, 'json')
}
// 保存部门
export const deptSave = (data: any) => {
  return request.post('/mt/crm/org/dept/save', data, 'json')
}
// 删除部门
export const deptDelete = (data: any) => {
  return request.post('/mt/crm/org/dept/delete', data, 'json')
}

// 查询可绑定的用户
export const queryBindableUserList = (data: any) => {
  return request.post('/mt/crm/org/queryBindableUserList', data, 'json')
}
// 查询员工
export const staffList = (data: any) => {
  return request.post('/mt/crm/org/staff/list', data, 'json')
}
// 新增员工
export const staffAdd = (data: any) => {
  return request.post('/mt/crm/org/staff/add', data, 'json')
}
// 编辑员工
export const staffEdit = (data: any) => {
  return request.post('/mt/crm/org/staff/edit', data, 'json')
}
// 删除员工
export const staffDelete = (data: any) => {
  return request.post('/mt/crm/org/staff/delete', data, 'json')
}

// 查询角色
export const roleList = (data: any) => {
  return request.post('/mt/crm/org/role/list', data, 'json')
}
// 保存角色
export const roleSave = (data: any) => {
  return request.post('/mt/crm/org/role/save', data, 'json')
}
// 删除角色
export const roleDelete = (data: any) => {
  return request.post('/mt/crm/org/role/delete', data, 'json')
}

// 保存页面菜单
export const pageConfigSave = (data: any) => {
  return request.post('/mt/crm/pageConfig/save', data, 'json')
}
// 查询菜单
export const pageConfigList = (data: any) => {
  return request.post('/mt/crm/pageConfig/list', data, 'json')
}
// 删除菜单
export const pageConfigDelete = (data: any) => {
  return request.post('/mt/crm/pageConfig/delete', data, 'json')
}

// 应用列表
export const basicAppList = (data: any) => {
  return request.post('/subgrade/basic/app/list', data, 'json')
}
