import { createRouter, createWebHistory } from 'vue-router'
import type {
  Router,
  RouteLocationNormalized,
  RouteRecordNormalized,
  RouteRecordRaw
} from 'vue-router'
import { isUrl } from '@/utils/is'
import { omit, cloneDeep } from 'lodash-es'

const modules = import.meta.glob('../views/**/*.{vue,tsx}')

/* Layout */
export const Layout = () => import('@/layout/Layout.vue')

export const getParentLayout = () => {
  return () =>
    new Promise((resolve) => {
      resolve({
        name: 'ParentLayout'
      })
    })
}

export const getRawRoute = (route: RouteLocationNormalized): RouteLocationNormalized => {
  if (!route) return route
  const { matched, ...opt } = route
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path
        }))
      : undefined) as RouteRecordNormalized[]
  }
}

// 前端控制路由生成
export const generateRoutesByFrontEnd = (
  routes: AppRouteRecordRaw[],
  keys: string[],
  basePath = '/'
): AppRouteRecordRaw[] => {
  const res: AppRouteRecordRaw[] = []

  for (const route of routes) {
    const meta = route.meta ?? {}
    // skip some route
    if (meta.hidden && !meta.canTo) {
      continue
    }

    let data: Nullable<AppRouteRecordRaw> = null

    let onlyOneChild: Nullable<string> = null
    if (route.children && route.children.length === 1 && !meta.alwaysShow) {
      onlyOneChild = (
        isUrl(route.children[0].path)
          ? route.children[0].path
          : pathResolve(pathResolve(basePath, route.path), route.children[0].path)
      ) as string
    }

    // 开发者可以根据实际情况进行扩展
    for (const item of keys) {
      // 通过路径去匹配
      if (isUrl(item) && (onlyOneChild === item || route.path === item)) {
        data = Object.assign({}, route)
      } else {
        const routePath = (onlyOneChild ?? pathResolve(basePath, route.path)).trim()
        if (routePath === item || meta.followRoute === item) {
          data = Object.assign({}, route)
        }
      }
    }

    // recursive child routes
    if (route.children && data) {
      data.children = generateRoutesByFrontEnd(
        route.children,
        keys,
        pathResolve(basePath, data.path)
      )
    }
    if (data) {
      res.push(data as AppRouteRecordRaw)
    }
  }
  return res
}

// 后端控制路由生成
export const generateRoutesByServer = (routes: AppCustomRouteRecordRaw[]): AppRouteRecordRaw[] => {
  const res: AppRouteRecordRaw[] = []

  for (const route of routes) {
    const data: AppRouteRecordRaw = {
      path: route.path,
      name: route.name,
      redirect: route.redirect,
      meta: route.meta
    }
    if (route.component) {
      const comModule = modules[`../${route.component}.vue`] || modules[`../${route.component}.tsx`]
      const component = route.component as string
      if (!comModule && !component.includes('#')) {
        console.error(`未找到${route.component}.vue文件或${route.component}.tsx文件，请创建`)
      } else {
        // 动态加载路由文件，可根据实际情况进行自定义逻辑
        data.component =
          component === '#' ? Layout : component.includes('##') ? getParentLayout() : comModule
      }
    }
    // recursive child routes
    if (route.children) {
      data.children = generateRoutesByServer(route.children)
    }
    res.push(data as AppRouteRecordRaw)
  }
  return res
}

export const pathResolve = (parentPath: string, path: string) => {
  if (isUrl(path)) return path
  const childPath = path.startsWith('/') || !path ? path : `/${path}`
  return `${parentPath}${childPath}`.replace(/\/\//g, '/').trim()
}

// 路由降级
export const flatMultiLevelRoutes = (routes: AppRouteRecordRaw[]) => {
  const modules: AppRouteRecordRaw[] = cloneDeep(routes)
  for (let index = 0; index < modules.length; index++) {
    const route = modules[index]
    if (!isMultipleRoute(route)) {
      continue
    }
    promoteRouteLevel(route)
  }
  return modules
}

// 层级是否大于2
const isMultipleRoute = (route: AppRouteRecordRaw) => {
  if (!route || !Reflect.has(route, 'children') || !route.children?.length) {
    return false
  }

  const children = route.children

  let flag = false
  for (let index = 0; index < children.length; index++) {
    const child = children[index]
    if (child.children?.length) {
      flag = true
      break
    }
  }
  return flag
}

// 生成二级路由
const promoteRouteLevel = (route: AppRouteRecordRaw) => {
  let router: Router | null = createRouter({
    routes: [route as RouteRecordRaw],
    history: createWebHistory()
  })

  const routes = router.getRoutes()
  addToChildren(routes, route.children || [], route)
  router = null

  route.children = route.children?.map((item) => omit(item, 'children'))
}

// 添加所有子菜单
const addToChildren = (
  routes: RouteRecordNormalized[],
  children: AppRouteRecordRaw[],
  routeModule: AppRouteRecordRaw
) => {
  for (let index = 0; index < children.length; index++) {
    const child = children[index]
    const route = routes.find((item) => item.name === child.name)
    if (!route) {
      continue
    }
    routeModule.children = routeModule.children || []
    if (!routeModule.children.find((item) => item.name === route.name)) {
      routeModule.children?.push(route as unknown as AppRouteRecordRaw)
    }
    if (child.children?.length) {
      addToChildren(routes, child.children, routeModule)
    }
  }
}

const componentMap = {
  Index: () => import('@/layout/Layout.vue'),
  DashboardIndex: () => import('@/views/business/dashboard/index.vue'),
  Client: () => import('@/layout/Layout.vue'),
  ClientList: () => import('@/views/business/client/list.vue'),
  ClientAuth: () => import('@/views/business/client/auth.vue'),
  ClientAuthDetail: () => import('@/views/business/client/authDetail.vue'),
  ClientDetail: () => import('@/views/business/client/detail.vue'),
  ClientRegister: () => import('@/views/business/client/register.vue'),
  ClientAuthProd: () => import('@/views/business/client/authProd.vue'),
  ClientAccount: () => import('@/views/business/client/accountDetail.vue'),
  Products: () => import('@/layout/Layout.vue'),
  ProductsList: () => import('@/views/business/products/list.vue'),
  ProductsUpstream: () => import('@/views/business/products/upstream.vue'),
  ProductsSource: () => import('@/views/business/products/source.vue'),
  ProductsSourceUpstream: () => import('@/views/business/products/upstream.vue'),
  ProductsAudit: () => import('@/views/business/products/audit.vue'),
  Financial: () => import('@/layout/Layout.vue'),
  FinancialBills: () => import('@/views/business/financial/bills.vue'),
  FinancialUpstream: () => import('@/views/business/financial/upstreamBills.vue'),
  FinancialDetail: () => import('@/views/business/financial/detail.vue'),
  FinancialInvoice: () => import('@/views/business/financial/invoice.vue'),
  FinancialInvoiceDetails: () => import('@/views/business/financial/invoiceDetails.vue'),
  Call: () => import('@/layout/Layout.vue'),
  CallList: () => import('@/views/business/call/list.vue'),
  CallDetail: () => import('@/views/business/call/detail.vue'),
  Authorization: () => import('@/layout/Layout.vue'),
  Department: () => import('@/views/Authorization/Department/Department.vue'),
  User: () => import('@/views/Authorization/User/User.vue'),
  Menu: () => import('@/views/Authorization/Menu/Menu.vue'),
  Role: () => import('@/views/Authorization/Role/Role.vue')
}
export const convertToRouterFormat = (menuList) => {
  return menuList
    .map((item) => {
      // 添加时的component字段只是一个展示的作用，动态加载组件使用的是componentMap[item.code]、不然vite会警告
      const route = {
        path: item.path || '', // 路由地址
        component: componentMap[item.code], // 动态加载组件
        name: item.code, // 组件名称
        redirect: item.redirectUrl || undefined, // 重定向地址
        meta: {
          title: item.title, // 菜单标题
          icon: item.icon || '', // 图标
          activeMenu: item.highlightMenu || undefined, // 高亮菜单
          alwaysShow: item.hiddenSidebar, // 是否始终显示侧边栏
          hidden: item.hidden, // 是否隐藏在菜单目录
          noCache: !item.cacheable, // 是否不缓存
          permission: item.buttons || [], // 路由权限
          canTo: true // 默认可以访问
        }
      }

      // 如果有子节点，则递归处理子节点
      if (item.subList && item.subList.length > 0) {
        route.children = convertToRouterFormat(item.subList)
      }
      // 如果是目录（isDir），则设置 alwaysShow 为 true 并添加 children 字段
      if (item.isDir) {
        route.alwaysShow = true
        route.children = route.children || [] // 确保目录至少有一个子节点
      }

      // 如果没有子节点且 hiddenSidebar 为 true，则不添加到路由中
      // if (!item.subList && item.hiddenSidebar) {
      //   return null
      // }

      return route
    })
    .filter(Boolean) // 过滤掉 null 值
}

export const collectPermissions = (routes) => {
  const permissions = new Set()

  function recurse(route) {
    if (Array.isArray(route)) {
      route.forEach(recurse)
    } else if (route && route.meta && Array.isArray(route.meta.permission)) {
      // 添加当前路由的权限到Set中
      route.meta.permission.forEach((p) => permissions.add(p.value))
    }

    // 递归处理子路由
    if (route.children) {
      recurse(route.children)
    }
  }

  recurse(routes)
  return Array.from(permissions) // 将Set转换为数组
}

export const updateHiddenStatus = (menuArray) => {
  menuArray.forEach((menu) => {
    // 如果当前菜单有子菜单
    if (menu.children && Array.isArray(menu.children)) {
      // 递归更新子菜单的 hidden 状态
      // updateHiddenStatus(menu.children)

      // 检查所有子菜单的 meta.hidden 是否都为 true
      const allChildrenHidden = menu.children.every((child) => child.meta.hidden)
      // 如果所有子菜单都隐藏，则将一级菜单的 meta.hidden 设置为 true
      if (allChildrenHidden) {
        menu.meta.hidden = true
      }
    }
  })
}
