import 'vue/jsx'
import { watch } from 'vue'

// 引入unocss
import '@/plugins/unocss'

// 导入全局的svg图标
import '@/plugins/svgIcon'

// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from './router'

import { createApp } from 'vue'

import App from './App.vue'

import './permission'

import { usePermissionStore } from '@/store/modules/permission'

// 创建实例
const setupAll = async () => {
  const app = createApp(App)

  await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)
  
  const permissionsStore = usePermissionStore()
  // 注册 v-permissions 自定义指令
  app.directive('permissions', {
    mounted(el, binding) {
      handlePermissionCheck(el, binding, permissionsStore);
    }
  });

  // // 监听权限变化并重新评估 v-permissions 指令
  // watch(
  //   () => permissionsStore.getPermissions,
  //   (newPermissions) => {
  //     reevaluatePermissions(newPermissions);
  //   },
  //   { immediate: true }
  // );

  app.mount('#app')
}

// 处理权限检查的辅助函数
function handlePermissionCheck(el, binding, permissionsStore) {
  const { value } = binding; // 获取绑定的权限代码数组
  if (Array.isArray(value)) {
    const hasPermission = value.some(permission => permissionsStore.getPermissions.includes(permission));
    el.style.display = hasPermission ? '' : 'none'; // 根据权限设置显示或隐藏
  } else {
    console.warn('v-permissions 需要一个权限代码数组作为参数');
  }
}

// // 重新评估所有使用 v-permissions 指令的元素
// function reevaluatePermissions(newPermissions) {
//   const elements = document.querySelectorAll('[v-permissions]');
//   elements.forEach((el) => {
//     const bindingValue = JSON.parse(el.getAttribute('v-permissions'));
//     if (Array.isArray(bindingValue)) {
//       const hasPermission = bindingValue.some(permission => newPermissions.includes(permission));
//       el.style.display = hasPermission ? '' : 'none';
//     }
//   });
// }

setupAll()
