import { defineStore } from 'pinia'
import { asyncRouterMap, constantRouterMap } from '@/router'
import {
  generateRoutesByFrontEnd,
  generateRoutesByServer,
  flatMultiLevelRoutes,
  convertToRouterFormat,
  collectPermissions,
  updateHiddenStatus
} from '@/utils/routerHelper'
import { store } from '../index'
import { cloneDeep } from 'lodash-es'
import { pageConfigList } from '@/api/department'

export interface PermissionState {
  routers: AppRouteRecordRaw[]
  addRouters: AppRouteRecordRaw[]
  isAddRouters: boolean
  menuTabRouters: AppRouteRecordRaw[]
  permissions: string[]
}

export const usePermissionStore = defineStore('permission', {
  state: (): PermissionState => ({
    routers: [],
    addRouters: [],
    isAddRouters: false,
    menuTabRouters: [],
    permissions: []
  }),
  getters: {
    getRouters(): AppRouteRecordRaw[] {
      return this.routers
    },
    getAddRouters(): AppRouteRecordRaw[] {
      return flatMultiLevelRoutes(cloneDeep(this.addRouters))
    },
    getIsAddRouters(): boolean {
      return this.isAddRouters
    },
    getMenuTabRouters(): AppRouteRecordRaw[] {
      return this.menuTabRouters
    },
    getPermissions(): string[] {
      return this.permissions // 获取权限数组
    }
  },
  actions: {
    generateRoutes(roles?: any): Promise<unknown> {
      return new Promise<void>(async (resolve) => {
        let routerMap: AppRouteRecordRaw[] = []
        // 获取当前路由
        const res = await pageConfigList({ appcode: 'crm' })
        const filteredRouterArr: AppRouteRecordRaw[] = convertToRouterFormat(res?.data || []) // 获取接口路由信息，重写路由结构

        updateHiddenStatus(filteredRouterArr) // 过滤没有可显示菜单的目录

        const permissions: any = collectPermissions(filteredRouterArr) // 获取了所有按钮权限
        // 更新权限状态
        this.setPermissions(permissions)
        //  asyncRouterMap
        routerMap = cloneDeep(filteredRouterArr)
        // 动态路由，404一定要放到最后面
        this.addRouters = routerMap.concat([
          {
            path: '/:path(.*)*',
            redirect: '/404',
            name: '404Page',
            meta: {
              hidden: true,
              breadcrumb: false
            }
          }
        ])
        // 渲染菜单的所有路由
        this.routers = cloneDeep(constantRouterMap).concat(routerMap)
        resolve()
      })
    },
    setIsAddRouters(state: boolean): void {
      this.isAddRouters = state
    },
    setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
      this.menuTabRouters = routers
    },
    setPermissions(permissions: string[]): void {
      this.permissions = permissions // 设置权限数组
    }
  },
  persist: {
    paths: ['routers', 'addRouters', 'menuTabRouters', 'permissions']
  }
})

export const usePermissionStoreWithOut = () => {
  return usePermissionStore(store)
}

export const hasPermission = (permission) => {
  return usePermissionStore().getPermissions.includes(permission)
}
