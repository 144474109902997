import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
/**
* redirect: noredirect        当设置 noredirect 的时候该路由在面包屑导航中不可被点击
* name:'router-name'          设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
* meta : {
    hidden: true              当设置 true 的时候该路由不会在侧边栏出现 如404，login等页面(默认 false)
    alwaysShow: true          当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式，
                              只有一个时，会将那个子路由当做根路由显示在侧边栏，
                              若你想不管路由下面的 children 声明的个数都显示你的根路由，
                              你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，
                              一直显示根路由(默认 false)
    title: 'title'            设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'          设置该路由的图标
    noCache: true             如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    breadcrumb: false         如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
    affix: true               如果设置为true，则会一直固定在tag项中(默认 false)
    noTagsView: true          如果设置为true，则不会出现在tag中(默认 false)
    activeMenu: '/dashboard'  显示高亮的路由路径
    canTo: true               设置为true即使hidden为true，也依然可以进行路由跳转(默认 false)
    permission: ['edit','add', 'delete']    设置该路由的权限
  }
**/
export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '',
    component: Layout,
    redirect: '/index',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: ':path(.*)',
        name: 'RedirectChild',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
  {
    path: '/account',
    component: Layout,
    redirect: '/account/index',
    name: 'Account',
    meta: {
      title: '账户设置',
      hidden: true
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/business/dashboard/account.vue'),
        name: 'AccountIndex',
        meta: {
          title: '账户设置',
          noCache: true,
          hidden: true,
          canTo: true
        }
      }
    ]
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'Index',
    meta: {
      title: '首页',
      icon: 'hugeicons:home-04'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/business/dashboard/index.vue'),
        name: 'DashboardIndex',
        meta: {
          title: '首页',
          icon: 'hugeicons:home-04'
        }
      }
    ]
  },
  {
    path: '/client',
    component: Layout,
    name: 'Client',
    redirect: '/client/list',
    meta: {
      title: '客户管理',
      icon: 'fluent:people-list-20-regular',
      alwaysShow: true
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/business/client/list.vue'),
        name: 'ClientList',
        meta: {
          title: '客户列表'
        }
      },
      {
        path: 'auth',
        component: () => import('@/views/business/client/auth.vue'),
        name: 'ClientAuth',
        meta: {
          title: '认证记录'
        }
      },
      {
        path: 'authDetail',
        component: () => import('@/views/business/client/authDetail.vue'),
        name: 'ClientAuthDetail',
        meta: {
          title: '认证详情',
          activeMenu: '/client/auth',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'detail',
        component: () => import('@/views/business/client/detail.vue'),
        name: 'ClientDetail',
        meta: {
          title: '客户详情',
          activeMenu: '/client/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'register',
        component: () => import('@/views/business/client/register.vue'),
        name: 'ClientRegister',
        meta: {
          title: '注册客户',
          activeMenu: '/client/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'authProd',
        component: () => import('@/views/business/client/authProd.vue'),
        name: 'ClientAuthProd',
        meta: {
          title: '授权产品',
          activeMenu: '/client/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'account',
        component: () => import('@/views/business/client/accountDetail.vue'),
        name: 'ClientAccount',
        meta: {
          title: '账户管理',
          activeMenu: '/client/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/products',
    component: Layout,
    name: 'Products',
    redirect: '/products/list',
    meta: {
      title: '产品管理',
      icon: 'circum:view-list',
      alwaysShow: true
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/business/products/list.vue'),
        name: 'ProductsList',
        meta: {
          title: '产品列表'
        }
      },
      {
        path: 'upstream',
        component: () => import('@/views/business/products/upstream.vue'),
        name: 'ProductsUpstream',
        meta: {
          title: '上游配置',
          activeMenu: '/products/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'source',
        component: () => import('@/views/business/products/source.vue'),
        name: 'ProductsSource',
        meta: {
          title: '上游管理'
        }
      },
      {
        path: 'sourceUpstream',
        component: () => import('@/views/business/products/upstream.vue'),
        name: 'ProductsSourceUpstream',
        meta: {
          title: '上游配置',
          activeMenu: '/products/source',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'audit',
        component: () => import('@/views/business/products/audit.vue'),
        name: 'ProductsAudit',
        meta: {
          title: '产品审核 '
        }
      }
    ]
  },
  {
    path: '/financial',
    component: Layout,
    name: 'Financial',
    redirect: '/financial/bills',
    meta: {
      title: '财务管理',
      icon: 'ph:wallet-thin'
    },
    children: [
      {
        path: 'bills',
        component: () => import('@/views/business/financial/bills.vue'),
        name: 'FinancialBills',
        meta: {
          title: '客户账单'
        }
      },
      {
        path: 'upstream',
        component: () => import('@/views/business/financial/upstreamBills.vue'),
        name: 'FinancialUpstream',
        meta: {
          title: '上游账单'
        }
      },
      {
        path: 'detail',
        component: () => import('@/views/business/financial/detail.vue'),
        name: 'FinancialDetail',
        meta: {
          title: '账单详情',
          activeMenu: '/financial/bills',
          noCache: true,
          hidden: true,
          canTo: true
        }
      },
      {
        path: 'invoice',
        component: () => import('@/views/business/financial/invoice.vue'),
        name: 'FinancialInvoice',
        meta: {
          title: '开票记录'
        }
      },
      {
        path: 'invoiceDetails',
        component: () => import('@/views/business/financial/invoiceDetails.vue'),
        name: 'FinancialInvoiceDetails',
        meta: {
          title: '开票明细',
          activeMenu: '/financial/invoice',
          noCache: true,
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/call',
    component: Layout,
    name: 'Call',
    redirect: '/call/record',
    meta: {
      title: '调用管理',
      icon: 'ph:wallet-thin',
      alwaysShow: true
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/business/call/list.vue'),
        name: 'CallList',
        meta: {
          title: '调用记录'
        }
      },
      {
        path: 'detail',
        component: () => import('@/views/business/call/detail.vue'),
        name: 'CallDetail',
        meta: {
          title: '调用详情',
          activeMenu: '/call/list',
          noCache: true,
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/authorization',
    component: Layout,
    redirect: '/authorization/user',
    name: 'Authorization',
    meta: {
      title: '权限管理',
      icon: 'lets-icons:setting-line-light',
      alwaysShow: true
    },
    children: [
      {
        path: 'department',
        component: () => import('@/views/Authorization/Department/Department.vue'),
        name: 'Department',
        meta: {
          title: '部门管理'
        }
      },
      {
        path: 'user',
        component: () => import('@/views/Authorization/User/User.vue'),
        name: 'User',
        meta: {
          title: '员工管理'
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/Authorization/Menu/Menu.vue'),
        name: 'Menu',
        meta: {
          title: '菜单管理'
        }
      },
      {
        path: 'role',
        component: () => import('@/views/Authorization/Role/Role.vue'),
        name: 'Role',
        meta: {
          title: '角色管理'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
